<template>
  <div class="EmailView">
    <HeaderStep
      :logo="flow?.headerLogo"
      :theme="currentStep.headerTheme"
      :title="$t('flows.shared.steps.email.title')"
    />

    <div class="container">
      <form
        id="EmailView"
        @submit.prevent="handleSubmit">

        <InputText
          class="mt-3"
          ref="input"
          v-model.trim="session.email.value"
          type="email"
          :placeholder="$t('shared.businessEmail')"
          :is-placeholder-persistent="true"
          :is-disabled="isSubmittingEmail"
          :is-invalid="isEmailError"
        />
        <ErrorMessage
          class="__error"
          v-show="isEmailError || isEmailRateLimitError">
          {{ computedErrorMessage }}
        </ErrorMessage>

        <div
          class="text-center mt-4">
          <ButtonBase
            class="uppercase"
            type="submit"
            :is-loading="isSubmittingEmail">
            {{ $t('ctas.signUp') }}
          </ButtonBase>
        </div>

      </form>
    </div>
  </div>
</template>


<script>
import useEmail from '@/use/email';
import ButtonBase from '@/components/ButtonBase.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import HeaderStep from '@/components/HeaderStep.vue';
import InputText from '@/components/InputText.vue';

export default {
  name: 'EmailView',

  components: {
    ButtonBase,
    ErrorMessage,
    HeaderStep,
    InputText,
  },

  inject: [
    'flow',
    'session',
    'nextStep',
    'currentStep',
    'trackEvent',
  ],

  setup() {
    const {
      isEmailError,
      isEmailRateLimitError,
      isSubmittingEmail,
      submitEmail,
    } = useEmail();

    return {
      isEmailError,
      isEmailRateLimitError,
      isSubmittingEmail,
      submitEmail,
    };
  },

  computed: {
    computedErrorMessage() {
      return this.isEmailRateLimitError
        ? this.$t('shared.rateLimitError')
        : this.$t('shared.businessEmailError');
    },
  },

  mounted() {
    this.focusEmailInput();
  },

  methods: {
    handleSubmit() {
      this.submitEmail({
        flowName: this.$route.params.flowName,
        session: this.session,
        onSuccess: this.handleSuccess,
        onError: this.handleError,
      });
    },

    handleSuccess() {
      this.trackEvent('submitted email address', this.currentStep, this.session);

      this.$router.push({
        name: 'step',
        params: {
          lang: this.$route.params.lang || '',
          flowName: this.$route.params.flowName,
          stepName: this.nextStep.routeName,
        },
        query: this.$route.query,
      });
    },

    handleError() {
      this.focusEmailInput();
    },

    focusEmailInput() {
      setTimeout(() => this.$refs.input.select(), 100);
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.__error {
  margin-top: 2px;
}
</style>
